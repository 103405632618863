<template>
  <v-container
    fluid
    class="px-md-4"
  >
    <v-row
      v-if="!loading"
      class="my-2"
      no-gutters
    >
      <v-col cols="12" class="mb-4">
        <v-card class="pa-4 fill-height">
          <v-row
            no-gutters
            class="fill-height"
          >
            <v-col cols="12" class="d-flex justify-center">
              <v-card-title>
                {{ $t('customerServiceDashboard.hello') }}, {{ $store.getters.userCommonData.name }} {{ $store.getters.userCommonData.surname }}
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <template
        v-if="$permission('SystemStats')"
      >
        <v-col cols="12" md="6" class="mb-4 mb-md-0">
          <v-card class="mr-md-2 pa-4 fill-height">
            <v-row
              no-gutters
              class="fill-height"
            >
              <v-col cols="12" md="6">
                <v-card-title>
                  {{ $t('customerServiceDashboard.waitingCompanies') }}
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p v-if="data.registerCompanyIssuesWaitingForAssign !== null">
                        {{ $t('customerServiceDashboard.registerCompanyIssuesWaitingForAssign') }}: {{ data.registerCompanyIssuesWaitingForAssign }}
                      </p>
                      <p v-if="data.generalIssuesWaitingForAssign !== null">
                        {{ $t('customerServiceDashboard.generalIssuesWaitingForAssign') }}: {{ data.generalIssuesWaitingForAssign }}
                      </p>
                      <p v-if="data.companiesWaitingForAcceptance !== null">
                        {{ $t('customerServiceDashboard.companiesWaitingForAcceptance') }}: {{ data.companiesWaitingForAcceptance }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
              <v-col
                v-if="data.registerCompanyIssuesWaitingForAssign || data.generalIssuesWaitingForAssign"
                cols="12" md="6"
                style="width: 50%; max-width: 280px;"
                class="d-flex align-center mx-auto mx-md-0"
              >
                <Chart
                  id="dashboard-waiting-companies-chart"
                  :chart-data="waitingCompaniesChartData"
                  :chart-options="chartOptions"
                  :chart-type="chartType"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="mb-4 mb-md-0">
          <v-card class="ml-md-2 pa-4 fill-height">
            <v-row
              no-gutters
              class="fill-height"
            >
              <v-col cols="12" md="6">
                <v-card-title>
                  {{ $t('customerServiceDashboard.closedIssues') }}
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-row no-gutters>
                        <p>{{ $t('customerServiceDashboard.issuesClosedInMonth') }}: {{ data.issuesClosedInMonth }}</p>
                      </v-row>
                      <v-row no-gutters>
                        <p>{{ $t('customerServiceDashboard.issuesClosedInYear') }}: {{ data.issuesClosedInYear }}</p>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
              <v-col
                v-if="data.issuesClosedInMonth || data.issuesClosedInYear"
                cols="12" md="6"
                style="width: 50%; max-width: 280px;"
                class="d-flex align-center mx-auto mx-md-0"
              >
                <Chart
                  id="dashboard-closed-issues-chart"
                  :chart-data="closedIssuesChartData"
                  :chart-options="chartOptions"
                  :chart-type="chartType"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="mb-4 mt-4 mb-md-0">
          <v-card class="mr-md-2 pa-4 fill-height">
            <v-row
              no-gutters
              class="fill-height"
            >
              <v-col cols="12" md="6">
                <v-card-title>
                  {{ $t('customerServiceDashboard.users') }}
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p>
                        {{ $t('customerServiceDashboard.currentlyLoggedUsers') }}: {{ data.currentlyLoggedUsers }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import Service from '@/services'
import Chart from '@/components/Chart'

export default {
  name: 'CustomerServiceDashboard',
  components: {
    Chart
  },
  data () {
    return {
      loading: false,
      data: {
        companiesWaitingForAcceptance: null,
        generalIssuesWaitingForAssign: null,
        issuesClosedInMonth: null,
        issuesClosedInYear: null,
        registerCompanyIssuesWaitingForAssign: null
      },
      chartType: 'doughnut',
      chartOptions: {
        legend: {
          display: false
        }
      }
    }
  },
  computed: {
    closedIssuesChartData () {
      return {
        labels: [
          this.$t('customerServiceDashboard.issuesClosedInMonth'),
          this.$t('customerServiceDashboard.issuesClosedInYear')
        ],
        datasets: [{
          data: [
            this.data.issuesClosedInMonth,
            this.data.issuesClosedInYear
          ],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)'
          ],
          hoverOffset: 4
        }]
      }
    },
    waitingCompaniesChartData () {
      const labels = []
      const data = []

      if (this.data.registerCompanyIssuesWaitingForAssign !== null) {
        data.push(this.data.registerCompanyIssuesWaitingForAssign)
        labels.push(this.$t('customerServiceDashboard.registerCompanyIssuesWaitingForAssign'))
      }

      if (this.data.generalIssuesWaitingForAssign !== null) {
        data.push(this.data.generalIssuesWaitingForAssign)
        labels.push(this.$t('customerServiceDashboard.generalIssuesWaitingForAssign'))
      }

      if (this.data.companiesWaitingForAcceptance != null) {
        data.push(this.data.companiesWaitingForAcceptance)
        labels.push(this.$t('customerServiceDashboard.companiesWaitingForAcceptance'))
      }

      return {
        labels: labels,
        datasets: [{
          data,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const { data } = await Service.get('/CustomerService/GetDashboardData')
      this.data = data
    }
  }
}
</script>
